import type { Referential, SearchProductsQueryParameters, VehicleTypeInteger } from '@yescapa-dev/ysc-api-js/legacy'

/* EVENTS */
export const SEARCH_REQUEST_EVENT = 'search'
export const SEARCH_SELECT_ITEM_EVENT = 'select_item'

/* PARAMS */
export const SEARCH_TERM_PARAM = 'search_term'
export const SEARCH_MIN_PRICE_PARAM = 'price_min'
export const SEARCH_MAX_PRICE_PARAM = 'price_max'
export const SEARCH_SEATS_PARAM = 'filter_seats'
export const SEARCH_BEDS_PARAM = 'filter_beds'
export const SEARCH_TYPES_PARAM = 'filter_types'
export const SEARCH_INSTANT_BOOKING_PARAM = 'filter_instant_booking'
export const SEARCH_OWNER_TYPES_PARAM = 'filter_owner_types'
export const SEARCH_OWNER_TYPES_PRIVATE_PARAM = 'filter_owner_types_private'
export const SEARCH_OWNER_TYPES_PRO_PARAM = 'filter_owner_types_pro'
export const SEARCH_DISCOUNT_WEEKLY_PARAM = 'filter_discount_weekly'
export const SEARCH_DISCOUNT_EARLY_PARAM = 'filter_discount_early'
export const SEARCH_PETS_ALLOWED_PARAM = 'filter_pets_allowed'
export const SEARCH_TRAVEL_ABROAD_PARAM = 'filter_travel_abroad_allowed'
export const SEARCH_UNLIMITED_KM_PARAM = 'filter_unlimited_km_allowed'
export const SEARCH_RECENT_VEHICLE_PARAM = 'filter_recent_vehicle'
export const SEARCH_WINTER_TIRE_PARAM = 'filter_winter_tire'
export const SEARCH_WINTER_CHAIN_PARAM = 'filter_winter_chain'
export const SEARCH_REVIEW_AVERAGE_PARAM = 'filter_review_average'
export const SEARCH_HIGH_LIMIT_PARAM = 'filter_vehicle_high_limit'
export const SEARCH_SELECT_ITEM_ID_PARAM = 'item_id'
export const SEARCH_SELECT_ITEM_CATEGORY_PARAM = 'item_category'

const filters: Record<string, string> = {
  where: SEARCH_TERM_PARAM,
  min_price: SEARCH_MIN_PRICE_PARAM,
  max_price: SEARCH_MAX_PRICE_PARAM,
  seatbelts: SEARCH_SEATS_PARAM,
  beds: SEARCH_BEDS_PARAM,
  types: SEARCH_TYPES_PARAM,
  instant_booking: SEARCH_INSTANT_BOOKING_PARAM,
  owner_types: SEARCH_OWNER_TYPES_PARAM,
  discount_weekly: SEARCH_DISCOUNT_WEEKLY_PARAM,
  discount_early: SEARCH_DISCOUNT_EARLY_PARAM,
  pets: SEARCH_PETS_ALLOWED_PARAM,
  international: SEARCH_TRAVEL_ABROAD_PARAM,
  km_unlimited: SEARCH_UNLIMITED_KM_PARAM,
  less_than_five: SEARCH_RECENT_VEHICLE_PARAM,
  winter_tire: SEARCH_WINTER_TIRE_PARAM,
  winter_chain: SEARCH_WINTER_CHAIN_PARAM,
  review_average__gte: SEARCH_REVIEW_AVERAGE_PARAM,
  vehicle_height: SEARCH_HIGH_LIMIT_PARAM,
}

export const getSearchAnalyticsParams = (query: SearchProductsQueryParameters, vehicleTypesAsInt: Referential['maps']['vehicle_type_as_int']) => {
  const analyticParams: Record<string, string> = {}
  for (const [key, value] of Object.entries(query)) {
    if (!Object.prototype.hasOwnProperty.call(filters, key)) {
      continue
    }
    if (key === 'types') {
      // Use assertion as VehicleTypeInteger because we know key === 'types'
      if (value?.length) {
        analyticParams[filters.types] = Array.isArray(value)
          ? value
              .reduce((acc: string[], val: string) => {
                acc.push(vehicleTypesAsInt[+val as VehicleTypeInteger]?.key)
                return acc
              }, [])
              .join(', ')
          : vehicleTypesAsInt[+value as VehicleTypeInteger].key
      }
    }
    else if (value) {
      analyticParams[filters[key]] = value === 'true' ? 'yes' : value
    }
  }
  return analyticParams
}
