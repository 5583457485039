import type { AppCamperSearch, AppCamperSearchQuery } from '@yescapa-dev/ysc-api-js/modern'
import { SEARCH_REQUEST_EVENT, getSearchAnalyticsParams } from '~/utils/analytics/search'

export const useSearchStore = defineStore('search', () => {
  const filters = ref(initialSearchFiltersFactory())

  const results = ref<AppCamperSearch[]>([])
  const isCrossedResults = ref<boolean>(false)
  const total = ref(0)

  const markedItemId = ref<number | null>(null)
  const hoveredItemId = ref<number | null>(null)

  const loading = ref(false)

  const page = ref(1)
  const pageSize = ref(20)
  const currentFilter = ref<string | null>(null)

  const renderMap = ref(false)
  const showModalFilters = ref(false)

  const showQuickSearch = ref(false)

  const markedItem = computed(() => {
    if (markedItemId.value === null) {
      return null
    }

    return results.value.find(camper => camper.id === markedItemId.value)
  })

  const { searchCampers: searchCampersApi } = useSearchCampers()

  const searchCampers = async (query: AppCamperSearchQuery) => {
    loading.value = true
    const { getReferentialMaps } = useReferentialStore()
    const { event } = useGtag()
    event(SEARCH_REQUEST_EVENT, {
      ...getSearchAnalyticsParams(query, getReferentialMaps('vehicle_type_as_int')),
    })

    markedItemId.value = null
    try {
      const data = await searchCampersApi({ ...query, for_search: true })
      results.value = data.results
      isCrossedResults.value = data.crossed_results
      total.value = data.count
    }
    finally {
      loading.value = false
    }
  }

  return {
    filters,
    results,
    total,
    markedItemId,
    markedItem,
    hoveredItemId,
    loading,
    page,
    pageSize,
    currentFilter,
    renderMap,
    showModalFilters,
    searchCampers,
    showQuickSearch,
    isCrossedResults,
  }
})
